<template>
  <div id="app">
    <div class="bg-white drawer">
      <input id="my-drawer" type="checkbox" class="drawer-toggle"> 
      <div class="flex flex-col drawer-content">
        <NavBar/>
        <router-view/>
        <Footer/>
      </div> 
                  
      <div class="drawer-side">
        <label for="my-drawer" class="drawer-overlay"></label> 
        <div class="menu p-4 overflow-y-auto w-80 bg-black text-base-content" id="mobile-menu">

          <router-link to="/" class="text-left text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">HOME</router-link>
          <hr/>
          <p
            class="text-left text-gray-300 block px-3 py-2 rounded-md text-base font-medium">INVESTORS</p>
          <router-link to="/regular_investors"
            class="text-left text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium pl-8">REGULAR
            INVESTORS</router-link>
          <router-link to="/institutional_investors"
            class="text-left text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium pl-8">INSTITUTIONAL
            INVESTORS</router-link>
          <hr/>
          <p
            class="text-left text-gray-300 px-3 py-2 rounded-md text-base font-medium">ABOUT</p>
          <router-link to="/about"
            class="text-left text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium pl-8">ABOUT aTRADE</router-link>
          <router-link to="/contact"
            class="text-left text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium pl-8">CONTACT</router-link>
          <router-link to="/faq"
            class="text-left text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium pl-8">FAQ</router-link>
          <hr/>
          <p
            class="text-left text-gray-300 px-3 py-2 rounded-md text-base font-medium">INVESTMENT
            OPTIONS</p>
          <router-link to="/how_to_invest"
            class="text-left text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium pl-8">HOW TO INVEST</router-link>
          <router-link to="/trade_fees"
            class="text-left text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium pl-8">TRADE FEES</router-link>
          <hr/>
          <a href="https://platform.atrade.co.za/page,Guest.SecurityOverview" target="_blank"
            class="text-left text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">MARKET PRICE</a>
          <a href="https://platform.atrade.co.za/page,Login" target="_blank"
            class="text-left text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">LOGIN</a>
          <a href="https://platform.atrade.co.za/page,Guest.Register.OpenAccount" target="_blank"
            class="text-left text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">REGISTER</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from './components/NavBar.vue'
import Footer from './components/Footer.vue'
export default {
  components: {
    NavBar,
    Footer
  }
}
</script>
<style>

#app {
  font-family: Poppins, sans-serif;
  font-weight: 100 200 300 400 500 600 700 800 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #18191a;
}

#mobile-menu a {
  font-weight: bold;
  color: #fff;
}

#mobile-menu a.router-link-exact-active {
  color: #00B2A9;
}
</style>
