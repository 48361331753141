<template>
    <nav id="nav" class="nav bg-black rounded-none">
      <div class="relative flex flex-row items-center justify-between h-24 lg:h-40">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <label for="my-drawer" class="absolute btn bg-black border-black drawer-button">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"/></svg>
          </label>
          <input type="checkbox" name="my-drawer" id="my-drawer">
                  
        </div>
        <div class="w-full flex md:items-center md:justify-center sm:items-stretch sm:justify-start">
          <div class="w-full md:w-1/3 lg:w-1/2">
            <img class="block lg:hidden h-10 w-full" src="../assets/svg/logo.svg" alt="A-Trade">
            <img class="mx-auto hidden lg:block h-10 w-auto" src="../assets/svg/logo.svg" alt="A-Trade">
          </div>
          <div class="w-1/2 md:w-2/3 lg:w-1/2 hidden sm:block">
            <div class="flex flex-col xl:flex-row align-end white-text">
              <div class="text-white pt-1">
                <router-link to="/" class="text-a-trade-blue hover:text-a-trade-blue font-bold text-base"
                  >HOME</router-link><span class="text-white font-bold text-base"> | </span>
                <div class="dropdown dropdown-hover ">
                  <div tabindex="0" class="m-1 text-white font-bold text-base">INVESTORS</div>
                  <ul tabindex="0" class="p-2 shadow menu dropdown-content bg-gray-700 w-64">
                    <li>
                      <router-link to="/regular_investors" class="bg-gray-700 hover:text-info">REGULAR
                        INVESTORS</router-link>
                    </li>
                    <li>
                      <router-link to="/institutional_investors" class="bg-gray-700 hover:bg-gray-100">INSTITUTIONAL
                        INVESTORS</router-link>
                    </li>
                  </ul>
                </div>
                <span class="text-white font-bold text-base"> | </span>
                <div class="dropdown dropdown-hover">
                  <div tabindex="0" class="m-1 text-white font-bold text-base">ABOUT</div>
                  <ul tabindex="0" class="p-2 shadow menu dropdown-content bg-gray-700 w-64">
                    <li>
                      <router-link to="/about" class="bg-gray-700 hover:text-info">ABOUT aTRADE</router-link>
                    </li>
                    <li>
                      <router-link to="/contact" class="bg-gray-700 hover:bg-gray-100">CONTACT</router-link>
                    </li>
                    <li>
                      <router-link to="/faq" class="bg-gray-700 hover:bg-gray-100">FAQ</router-link>
                    </li>
                  </ul>
                </div><span
                  class="text-white font-bold text-base"> | </span>
                <div class="dropdown dropdown-hover">
                  <div tabindex="0" class="m-1 text-white font-bold text-base">INVESTMENT OPTIONS</div>
                  <ul tabindex="0" class="p-2 shadow menu dropdown-content bg-gray-700 w-64">
                    <li>
                      <router-link to="/how_to_invest" class="bg-gray-700 hover:text-info">HOW TO
                        INVEST</router-link>
                    </li>
                    <li>
                      <router-link to="/trade_fees" class="bg-gray-700 hover:bg-gray-100">TRADE FEES</router-link>
                    </li>
                  </ul>
                </div>
                <span class="text-white font-bold text-base"> | </span>
                <a href="https://platform.atrade.co.za/page,Guest.SecurityOverview" target="_blank" class="text-white hover:text-a-trade-blue text-base font-bold">MARKET PRICES</a>
              </div>
              <div class="mt-1">
                <span class="xl:pl-6"><a href="https://platform.atrade.co.za/page,Login" target="_blank"
                    class="text-white bg-a-trade-blue text-lg hover:bg-gray-700 hover:text-white px-2 py-0 rounded-none font-bold">LOGIN</a></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
#nav a {
  font-weight: bold;
  color: #fff;
}

#nav a.router-link-exact-active {
  color: #00B2A9;
}
#mobile-menu a {
  font-weight: bold;
  color: #fff;
}

#mobile-menu a.router-link-exact-active {
  color: #00B2A9;
}
</style>