<template>
  <div class="home">
  <section class="flex flex-row bg-cover bg-auto bg-center" :style="{ backgroundImage: 'url(\'' + require('../assets/img/hero.jpg') + '\')' }">
    <div class="flex flex-col align_center justify-center w-1/2 ">
      <div class="w-9/12 flex flex-row align_center justify-center bg-a-trade-purple-dark p-6 ">
        <div class="hidden xl:block xl:w-1/2"></div>
        <span class="w-full lg:w-2/3 xl:w-1/2 flex flex-col justify-center align-center">
          <h1 class="text-left text-white font-bold align-center text-2xl lg:text-4xl xl:text-5xl">YOUR
            GATEWAY TO THE CAPE TOWN STOCK EXCHANGE</h1>
        </span>
      </div>
    </div>
    <div class="flex justify-center w-1/2 opacity-0 lg:opacity-100 overflow-clip overflow-hidden">
      <div class="flex flex-col align-center justify-end">
        <div class="grid grid-flow-row grid-cols-5 md:ml-32 ">
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24 "></div>
          <div class="h-24 w-24 "></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24 "></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24 "></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
          <div class="h-24 w-24"></div>
        </div>
      </div>
    </div>
  </section>

  <section class="flex flex-col lg:flex-row">
    <div class="flex flex-col w-full lg:w-3/6 bg-a-trade-blue justify-center h-auto xl:h-32">
      <div class="flex w-full justify-end">
        <h3 class="text-2xl md:text-3xl lg:text-4xl text-white font-bold w-full xl:w-3/4 lg:text-right"><router-link to="/how_to_invest" class="p-5">HOW TO INVEST</router-link></h3>
      </div>
    </div>
    <div class="flex flex-col w-full lg:w-3/6 bg-a-trade-red justify-center h-auto xl:h-32">
      <div class="flex w-full justify-start">
        <h3 class="text-2xl md:text-3xl lg:text-4xl text-white font-bold w-full xl:w-3/4 lg:text-left"><a href="https://platform.atrade.co.za/page,Guest.Register.OpenAccount" target="_blank" class="p-5">OPEN AN
          <span class="keep-together">aTRADE</span> ACCOUNT</a></h3>
      </div>
    </div>
  </section>

  <section class="w-full flex flex-row justify-center">
    <div class="w-2/3 py-10 align-center">
      <div>
        <h3 class="text-2xl md:text-4xl text-a-trade-blue text-center font-black leading-none">aTRADE MAKES TRADING ON
          THE
          CAPE TOWN STOCK EXCHANGE EASY</h3>
      </div>

      <p class="text-md md:text-2xl text-center xl:px-32">Our online trading technology provides easy account access, no
        minimum trading fees and access to research studies listed on the Cape Town Stock Exchange</p>
    </div>
  </section>
  <section class="w-full flex align-center bg-gray-100 p-0 lg:p-12">
    <div class="w-full flex flex-col lg:flex-row justify-center align-center">
      <article class="border-a-trade-purple-light w-full lg:w-96 m-3 rounded-xl">
        <img src="../assets/img/landing_1.jpg" class="object-cover w-full h-96 rounded-t-xl"/>
        <div class="flex flex-col bg-a-trade-purple-light pt-6 pb-3">
          <h4 class="text-2xl text-center text-white leading-none font-bold">REGULAR INVESTORS</h4>
          <span class="flex justify-center pt-2"><router-link to="/regular_investors"
              class="text-a-trade-purple-light text-2xl hover:bg-gray-700 bg-white hover:text-white px-1 py-0 rounded-none font-bold">VIEW
              MORE</router-link></span>
        </div>
      </article>
      <article class="border-a-trade-blue w-full lg:w-96 m-3 rounded-xl">
        <img src="../assets/img/landing_3.jpg" class="object-cover w-full h-96 rounded-t-xl"/>
        <div class="flex flex-col bg-a-trade-blue pt-6 pb-3">
          <h4 class="text-2xl text-center text-white leading-none font-bold">INSTITUTIONAL INVESTOR</h4>
          <span class="flex justify-center pt-2"><router-link to="/institutional_investors"
              class="text-a-trade-blue text-2xl hover:bg-gray-700 bg-white hover:text-white px-1 py-0 rounded-none font-bold">VIEW
              MORE</router-link></span>
        </div>
      </article>
    </div>
  </section>

  <section class="w-full flex flex-col md:mb-8">
    <div class="w-full flex flex-col bg-a-trade-purple-dark h-16 align-center md:mb-8">
      <h3 class="text-4xl text-white text-center my-auto leading-none font-bold">WHY aTRADE?</h3>
    </div>

    <div class="w-full flex flex-col xl:flex-row justify-center align-center md:h-88">
      <div class="w-full flex flex-col md:flex-row justify-end">
        <article class="w-full md:w-1/2 xl:w-1/3 md:py-3 md:px-3">
          <div class="bg-a-trade-orange h-auto px-8 py-6 md:rounded-xl h-full">
            <img class="h-24 md:h-28 mx-auto mb-6" src="../assets/svg/graph.svg">
            <h3 class="text-xl md:text-xl text-white text-center leading-3 font-bold mb-3 lg:mx-6">EASY TRADING
              ACCESS</h3>
            <p class="text-white text-center text-md leading-none">We eliminate the hassles and non-essentials of
              trading, providing simplified, efficient trading access.</p>
          </div>
        </article>
        <article class="w-full md:w-1/2 xl:w-1/3 md:py-3 md:px-3">
          <div class="bg-a-trade-purple-dark h-auto px-8 py-6 md:rounded-xl h-full">
            <img class="h-24 md:h-28 mx-auto mb-6" src="../assets/svg/calculator.svg">
            <h4 class="text-xl md:text-xl text-white text-center leading-none font-bold mb-3 lg:mx-6">NO FIXED MINIMUM
              FEES</h4>
            <p class="text-white text-center text-md leading-none">aTrade charges no fixed minimum fees, making it cost
              effective to invest in the Cape Town Stock Exchange securities even in the smallest increments.</p>
          </div>
        </article>
      </div>
      <div class="w-full flex flex-col md:flex-row">
        <article class="w-full md:w-1/2 xl:w-1/3 md:py-3 md:px-3">
          <div class="bg-a-trade-blue h-auto px-8 py-6 md:rounded-xl h-full">
            <img class="h-24 md:h-28 mx-auto mb-6" src="../assets/svg/checklist.svg">
            <h4 class="text-xl md:text-xl text-white text-center leading-none font-bold mb-3 lg:mx-6">RESEARCH</h4>
            <p class="text-white text-center md:text-md leading-none">With our vast knowledge and experience we provide
              access to research studies presented in an uncomplex manner which will help you to make informed
              investment or trading decisions.</p>
          </div>
        </article>
        <article class="w-full md:w-1/2 xl:w-1/3 md:py-3 md:px-3">
          <div class="bg-a-trade-purple-light h-auto px-8 py-6 md:rounded-xl h-full">
            <img class="h-24 md:h-28 mx-auto mb-6" src="../assets/svg/hands.svg">
            <h4 class="text-xl md:text-xl text-white text-center leading-none font-bold mb-3 lg:mx-6">NEW INVESTMENT
              OPPORTUNITIES</h4>
            <p class="text-white text-center text-md leading-none">aTrade provides a gateway to investing and trading
              new securities listed on the Cape Town Stock Exchange.</p>
          </div>
        </article>
      </div>
    </div>
  </section>

  <section class="flex flex-row h-96 w-full bg-cover bg-auto bg-center justify-center align-center bg-black relative">
    <img src="../assets/img/bg_image_home.jpg" class="absolute object-cover w-full h-96"/>
    <div class="absolute w-full bg-black h-96 opacity-50"></div>
    <div class="absolute flex justify-end align-end w-full opacity-0 md:opacity-100 mr-96 md:mr-0">
      <div class=" flex flex-col align-end justify-end">
        <div class="grid grid-flow-row grid-cols-5 md:ml-32 overflow-clip overflow-hidden">
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
          <div class="h-16 w-16"></div>
        </div>
      </div>
    </div>
    <div class="absolute flex flex-col w-5/6 lg:w-1/2 justify-center h-full align-center">
      <h3 class="text-3xl md:text-3xl text-white h-auto text-center align-center leading-none font-bold">
        aTRADE’S ONLINE TRADING SYSTEM GIVES CLIENTS ACCESS TO SECURITIES TRADING ON THE <span
          class=" text-a-trade-blue">CAPE TOWN STOCK EXCHANGE</span> VIA A SINGLE PLATFORM.</h3>
    </div>
  </section>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
   data: () => ({
      
   }),
}
</script>
